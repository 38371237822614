import { setPage, addRecords } from './data';

const initialState = {
  dealers: {
    pages: {},
    records: {},
  },
  me: {
    users: undefined,
    dealer: undefined,
  },
  actions: {
    success: false,
    error: null,
  },
  reseller_offers: {
    records: {},
    pages: {},
  },
};

export default function dealersReducer(state, action) {
  switch (action.type) {
    case 'DEALERS_LOADING':
      return setPage(state, 'dealers', action.query.page, null);

    case 'DEALERS_LOADED': {
      const ids = action.dealers.map(dealer => dealer.id);
      const records = action.dealers.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(
        state,
        'dealers',
        action.query.page,
        ids,
        action.pageInfo
      );

      return addRecords(stateWithPage, 'dealers', records);
    }

    case 'RESELLER_OFFERS_LOADING':
      return setPage(state, 'reseller_offers', action.query.page, null);

    case 'RESELLER_OFFERS_LOADED': {
      const ids = action.resellers.map(dealer => dealer.id);
      const records = action.resellers.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(
        state,
        'reseller_offers',
        action.query.page,
        ids,
        action.pageInfo
      );
      const offerId = action.query.filter.offer_id;

      return addRecords(stateWithPage, 'reseller_offers', { [offerId]: records });
    }

    case 'DEALER_LOADED': {
      const { dealer } = action;

      return addRecords(state, 'dealers', { [dealer.id]: dealer });
    }

    case 'MY_DEALER_LOADED': {
      const { dealer } = action;

      return { ...state, me: { ...state.me, dealer } };
    }

    case 'MY_DEALERS_USERS_LOADED': {
      const { users } = action;

      return { ...state, me: { ...state.me, users } };
    }

    case 'MY_DEALERS_USERS_ACTION_BEGIN':
      return { ...state, actions: { success: false, error: null } };

    case 'MY_DEALERS_USERS_ACTION_SUCCESS':
      return { ...state, actions: { success: true, error: null } };

    case 'MY_DEALERS_USERS_ACTION_ERROR':
      return { ...state, actions: { success: false, error: action.error } };

    case 'UPDATE_RESELLER_DEFAULT_LOADING':
      return {
        ...state,
        actions: { ...state.actions, success: false, error: null },
      };

    case 'UPDATE_RESELLER_DEFAULT_SUCCESS': {
      let key = Object.keys(action.payload);
      const updatedResellers = action.payload[key];
      const resellerRecords = updatedResellers.reduce((acc, reseller) => {
        return { ...acc, ...reseller };
      }, {});

      return addRecords(state, 'reseller_offers', { [key]: resellerRecords });
    }

    case 'UPDATE_RESELLER_DEFAULT_FAILURE':
      return {
        ...state,
        actions: { success: false, error: 'Failed to update reseller default' },
      };

    default:
      return state || initialState;
  }
}
